import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'
import { getAuth } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCEykZdwYHoo_QDpZSVBnlpAdLW1MweCfw",
  authDomain: "arbeitsplatz-tool-freiblick.firebaseapp.com",
  projectId: "arbeitsplatz-tool-freiblick",
  storageBucket: "arbeitsplatz-tool-freiblick.appspot.com",
  messagingSenderId: "5307197812",
  appId: "1:5307197812:web:7582f318648b0270202421",
  measurementId: "G-0SJSJDKL23"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const fn = getFunctions(app, 'europe-west3')
export const auth = getAuth(app)