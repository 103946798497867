<template>
  <div style="max-width:900px;margin:auto;padding-top:20px;padding:30px">
    <a href="https://www.freiblick.ch/">
      <b-image :src="require('@/assets/Freiblick_Logo_mit_Claim_4c.png')" alt="Freiblick" style="width:200px;height:20px;" class="pb-5 pl-1"></b-image>
    </a>
    <div class="columns mb-6 mt-6">
      <div class="card column has-background-success">
        <div class="card-content">
          <div class="content">
            <h1 class="title is-3 mb-0 has-text-white">{{freeCount}} frei</h1>
          </div>
        </div>
      </div>
      <div class="card column mx-3 has-background-danger">
        <div class="card-content">
          <div class="content">
            <h1 class="title is-3 mb-0 has-text-white">{{blockedCount}} belegt</h1>
          </div>
        </div>
      </div>
      <div class="card column has-my-background-grey">
        <div class="card-content">
          <div class="content">
            <h1 class="title is-3 mb-0 has-text-white">{{inactiveCount}} inaktiv</h1>
          </div>
        </div>
      </div>
    </div>
    <template>
      <b-table :data="workspaces" ref="table">
        <b-table-column v-slot="props" label="Platz Nr.">
          {{ props.row.title }}
        </b-table-column>

        <b-table-column v-slot="props" label="Beschreibung">
          {{ props.row.description }}
        </b-table-column>

        <b-table-column centered v-slot="props" label="Status">
          <span
            v-if="!props.row.active"
            class="tag has-my-background-grey has-text-white"
            style="min-width: 90px"
          >
            inaktiv
          </span>
          <span
            v-else
            :class="['tag', { 'is-danger': props.row.blocked }, { 'is-success': !props.row.blocked }]"
            style="min-width: 90px"
          >
            {{props.row.blocked ? "belegt" : "frei"}}
          </span>
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import { collection, query, onSnapshot } from "firebase/firestore"
import { onAuthStateChanged } from "firebase/auth"
import { db, auth } from "../firebase"

export default {
  data() {
    return {
      workspaces: [],
    }
  },
  computed: {
    inactiveCount () {
      return this.workspaces.filter(workspace => !workspace.active).length
    },
    blockedCount () {
      return this.workspaces.filter(workspace => workspace.active && workspace.blocked).length
    },
    freeCount () {
      return this.workspaces.filter(workspace => workspace.active && !workspace.blocked).length
    }
  },
  async created() {
    onAuthStateChanged(auth,
      () => {
        const q = query(collection(db, "workplace"))
        onSnapshot(q, (snapshot) => {
          this.workspaces = snapshot.docs.map((doc) => {
            return { ...doc.data(), id: doc.id }
          })
        })
      }
    )
  }
}
</script>

<style>
.has-my-background-grey {
  background-color: rgb(150, 150, 150) !important
}
@media screen and (max-width: 769px) {
  .title {
    font-size: 1.2em!important;
  }
  .card-content {
    padding: 10px ;
  }
  .card {
    margin-bottom: 10px;
    margin-left: auto!important;
    margin-right: auto!important;
  }
  b-table {
    margin-left: 30px!important;
    margin-right: 30px!important;
  }
  .mb-6 {
    margin-bottom: 1.8rem!important;
  }
}
</style>
